<template>
    <div>
        <ViewTitle :title="'業績報表 | 系統制繼續率發放資格'"></ViewTitle>
        <AnnualFilter
            :listData="listData"
            :isLoading="isLoadingTable"
            @show-data-table="showDataTable"
            v-model:dropdownOptionsProp="dropdownOptions"
            v-model:filterPayloadProp="filterPayload"
            v-model:filterLogProp="filterLog"
        />
        <SearchCondition
            :dataList="searchConditionList"
            :isShowTable="true"
            :isHasListData="listData.length > 0"
            :isLoading="isLoadingTable"
        />
        <div class="sum-wrapper" v-if="!isLoadingTable && listData.length > 0">
            <div>
                <div class="sum">
                    <div
                        v-for="(source, index) in totalPerformances"
                        v-text="`${source.title}：${source.value}`"
                        :key="index"
                        :class="source.class ?? ''"
                    />
                </div>
            </div>
        </div>
        <AnnualTable
            :listData="listData"
            :filterLog="filterLog"
            :isLoading="isLoadingTable"
            :emptyMessage="emptyMessage"
            :infos="[...searchConditionList, ...totalPerformances]"
        />
        <MobileAlertModal v-model="isShowMobileAlert" />
    </div>
</template>

<script>
import _ from 'lodash'
import ViewTitle from '@/components/ViewTitle.vue'
import SearchCondition from '@/components/SearchCondition.vue'
import AnnualFilter from '@/containers/report/AnnualFilter.vue'
import AnnualTable from '@/containers/report/AnnualTable.vue'
import MobileAlertModal from '@/containers/report/MobileAlertModal.vue'
import {
    getReportListAnnualBranchAPI,
    getReportListAnnualPersonalAPI
} from '@/assets/javascripts/api'

export default {
    name: 'ReportAnnual',
    components: {
        ViewTitle,
        AnnualFilter,
        MobileAlertModal,
        AnnualTable,
        SearchCondition
    },
    methods: {
        showDataTable: async function () {
            const payload = this.filterPayload
            if (!payload.manCode) return
            this.isLoadingTable = true
            this.$showLoading()

            let res
            if (payload.bgCode) {
                // 檢視分行
                try {
                    res = await this.$getResponse(
                        payload,
                        {
                            apiAlias: 'branchList',
                            apiName: '分行報表'
                        },
                        getReportListAnnualBranchAPI
                    )
                    if (res.data?.data) {
                        this.listData = Object.values(res.data?.data)
                        this.branchSum = this.$numberWithComma(
                            _.sumBy(
                                Object.values(res.data?.data),
                                'fybPersistency'
                            )
                        )
                    }
                } catch (error) {
                    this.listData = []
                }
            } else {
                // 檢視業務主管
                try {
                    res = await this.$getResponse(
                        payload,
                        {
                            apiAlias: 'personalList',
                            apiName: '個人報表'
                        },
                        getReportListAnnualPersonalAPI
                    )
                    const resSources = Object.keys(res.data)
                    let apiStatus = this.checkAPIStatus(resSources)
                    this.apiDataProcess(res, apiStatus)
                } catch (error) {
                    this.listData = []
                }
            }

            //資訊區受理區間
            let startDate = res?.data?.range?.startDate
            let endDate = res?.data?.range?.endDate

            if (this.filterLog?.dataType?.value === '2') {
                startDate = startDate.substring(0, 7)
                endDate = endDate.substring(0, 7)
            }

            this.dataRange.start = startDate
            this.dataRange.end = endDate
            this.isLoadingTable = false
            this.$hideLoading()
        },
        checkAPIStatus: function (data) {
            // 若沒資料API response連key都沒有，要檢查
            if (data.some((key) => this.sources.includes(key))) {
                return 'success'
            } else {
                return 'no_data'
            }
        },
        apiDataProcess: function (res, status) {
            if (status === 'success') {
                this.listData = this.supervisorHandler(res.data)
                this.sourcesFyb = res.data?.self?.performancePersistency
                this.sourcesFyb.self = res.data?.self?.fybPersistency
                this.sourcesFyb.sum = _.sum(Object.values(this.sourcesFyb)) || 0

                Object.keys(this.sourcesFyb).forEach((fybName) => {
                    this.sourcesFyb[fybName] = this.$numberWithComma(
                        this.sourcesFyb[fybName]
                    )
                })
            } else {
                const modeLabel = this.filterLog.relationMode.label
                this.listData = []
                this.emptyMessage = `查詢區間無${modeLabel}資料<br/>請嘗試其他選項`
            }
        },
        supervisorHandler: function (data) {
            let dataList = []
            // 選擇全部 要另外處理加總
            this.sources.forEach((sourceKey) => {
                dataList = [
                    ...dataList,
                    ...this.dataInsertSource(data, sourceKey)
                ]
            })
            return dataList
        },
        dataInsertSource: function (data, source) {
            if (data[source] === undefined) return []
            if (source === 'self') {
                // 處理個人業績，response格式和其他人不一樣呢，是Object不是Array
                data[source].source = this.getSourceLabel(source)
                return [data[source]]
            } else {
                // 處理推薦線(recommend)、育成線(csGuide)、跨區推薦線(csRecommend)
                return Object.values(data[source]).map((row) => {
                    row.source = this.getSourceLabel(source)
                    return row
                })
            }
        },
        getSourceLabel: function (sourceKey) {
            return this.dropdownOptions.performanceSource.find(
                (source) => source.key === sourceKey
            )?.value
        }
    },
    computed: {
        searchConditionList: function () {
            return [
                {
                    title: `${
                        this.filterLog?.dataType?.value === '1'
                            ? '受理日'
                            : '核實月'
                    }區間`,
                    value: `${this.dataRange.start} 至 ${this.dataRange.end}`
                }
            ]
        },
        totalPerformances: function () {
            return this.filterLog.role?.value === 'supervisor'
                ? [
                      {
                          title: '業績總和',
                          value: this.sourcesFyb.sum,
                          class: 'sum-item'
                      },
                      {
                          title: '您的業績',
                          value: this.sourcesFyb.self,
                          class: 'sum-item'
                      },
                      {
                          title: `${this.getSourceLabel('recommend')}業績`,
                          value: this.sourcesFyb.recommend
                      },
                      {
                          title: `${this.getSourceLabel('csGuide')}業績`,
                          value: this.sourcesFyb.csGuide
                      },
                      {
                          title: `${this.getSourceLabel('csRecommend')}業績`,
                          value: this.sourcesFyb.csRecommend
                      }
                  ]
                : [
                      {
                          title: '業績總和',
                          value: this.branchSum
                      }
                  ]
        },
        sources: function () {
            return this.dropdownOptions.performanceSource.map(
                (source) => source.key
            )
        }
    },
    data() {
        return {
            isShowMobileAlert: false,
            isLoadingTable: false,
            listData: [],
            dropdownOptions: {
                dataType: [],
                performanceSource: [],
                relationMode: []
            },
            filterPayload: {
                titleType: '',
                manCode: '',
                role: '',
                bgCode: '',
                endDate: '',
                dataType: '',
                relationMode: ''
            },
            filterLog: {},
            dataRange: {
                start: '',
                end: ''
            },
            sourcesFyb: {
                recommend: 0,
                csGuide: 0,
                csRecommend: 0,
                self: 0,
                sum: 0
            },
            branchSum: 0,
            emptyMessage: ''
        }
    },
    mounted() {
        this.isShowMobileAlert = this.$isMobile() && !this.$isMobileHorizontal()
        if (!this.$isTitleCAup() && !this.$isAdmin())
            this.$setGaEvent('titleCA', 'check-UserTitle')
    }
}
</script>
<style lang="scss" scoped>
.sum-wrapper {
    .sum {
        display: flex;
        flex-wrap: wrap;
        color: $secondary-grey;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 15px;
        width: 100%;
        column-gap: 30px;
        div {
            width: 250px;
        }
        &:first-child {
            margin-right: 20px;
        }
        .sum-item {
            flex-basis: 100%;
        }
    }
}
</style>
